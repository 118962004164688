<template>
  <div class="page-info-content">
    <DetailsPage :details-item-arr="detailsItemArr" :detail-obj="ruleForm" details-title="企业信息" />
    <hr class="dividing-line">
    <el-form ref="ruleForm" :model="ruleForm" :rules="rules" class="page-form form-item-row" label-position="left" label-width="165px">
      <!-- <el-form-item label="客户名称：">
        <div>{{ ruleForm.cmpName }}</div>
      </el-form-item>
      <el-form-item label="统一社会信用代码：">
        <div>{{ ruleForm.cmpUnicode }}</div>
      </el-form-item>
      <el-form-item label="所在地址：">
        <div>{{ ruleForm.currAddr }}</div>
      </el-form-item>
      <el-form-item label="营业执照：">
        <img v-if="ruleForm.leadIdcardBackNamePath" class="img-class" :src="$fileUrl + ruleForm.businessLicenseNamePath" alt="图片">
        <div v-else class="default-graph">
          <img :src="$defaultGraph" alt="">
        </div>
      </el-form-item>
      <el-form-item label="法人身份证正面：">
        <img v-if="ruleForm.leadIdcardBackNamePath" class="img-class" :src="$fileUrl + ruleForm.leadIdcardFrontNamePath" alt="图片">
        <div v-else class="default-graph">
          <img :src="$defaultGraph" alt="">
        </div>
      </el-form-item>
      <el-form-item label="法人身份证反面：">
        <img v-if="ruleForm.leadIdcardBackNamePath" class="img-class" :src="$fileUrl + ruleForm.leadIdcardBackNamePath" alt="图片">
        <div v-else class="default-graph">
          <img :src="$defaultGraph" alt="">
        </div>
      </el-form-item> -->
      <el-form-item label="代采机构：" prop="fundsCmpCode">
        <el-select v-model="ruleForm.fundsCmpCode" filterable placeholder="请选择" :remote-method="getCmpBaseinfoList">
          <el-option v-for="item in employerCmpList" :key="item.cmpCode" :label="item.cmpName" :value="item.cmpCode">
            <p @click="getEmployerCmpName(item)">
              {{ item.cmpName }}
            </p>
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item prop="cmpCreditReportNamePath" label="企业征信报告：">
        <FileUpload :edit-file-name="ruleForm.cmpCreditReportName" @getFile="getcmpCreditReportFile" />
      </el-form-item>
      <el-form-item prop="financialReportsNamePath" label="财务报表：">
        <FileUpload :edit-file-name="ruleForm.financialReportsName" @getFile="getFinancialReportsFile" />
      </el-form-item>
      <el-form-item prop="balanceSheetNamePath" label="资产负债表：">
        <FileUpload :edit-file-name="ruleForm.balanceSheetName" @getFile="getBalanceSheetFile" />
      </el-form-item>
      <el-form-item prop="rulesNamePath" label="公司章程：">
        <FileUpload :edit-file-name="ruleForm.rulesName" @getFile="getRulesNameFile" />
      </el-form-item>
      <el-form-item prop="taxReturnNamePath" label="纳税申报表：">
        <FileUpload :edit-file-name="ruleForm.taxReturnName" @getFile="getTaxReturnFile" />
      </el-form-item>
      <el-form-item prop="flowMeterNamePath" label="流水表：">
        <FileUpload :edit-file-name="ruleForm.flowMeterName" @getFile="getFlowMeterNameFile" />
      </el-form-item>
      <el-form-item prop="topFiveTransacNamePath" label="上下游前五交易报表：">
        <FileUpload :edit-file-name="ruleForm.topFiveTransacName" @getFile="getTopFiveTransacFile" />
      </el-form-item>
      <el-form-item prop="othAccessNamePath" label="其他附件：">
        <FileUpload :edit-file-name="ruleForm.othAccessName" @getFile="getOthAccessFile" />
      </el-form-item>
      <el-form-item prop="contacts" label="联系人：">
        <el-input
          v-model="ruleForm.contacts"
          placeholder="请输入联系人"
        />
      </el-form-item>
      <el-form-item prop="contactsPhone" label="联系电话：">
        <el-input
          v-model="ruleForm.contactsPhone"
          placeholder="请输入联系电话"
        />
      </el-form-item>
      <div class="form-operation">
        <el-button size="small" plain class="widen-button" @click="cancel">
          取消
        </el-button>
        <el-button size="small" type="primary" :loading="$store.state.loading" class="widen-button" @click="submitForm">
          提交
        </el-button>
      </div>
    </el-form>
  </div>
</template>

<script>
import FileUpload from '@/components/FileUpload'
import DetailsPage from '@/components/DetailsPage'
import { getSelectAreaTree } from '@/http/custApi/common'
import { cmpBaseinfoDetail } from '@/http/custApi/login'
import { agentFinancialInfoAdd } from '@/http/custApi/quotaManage'
import { getCmpBaseinfoList } from '@/http/custApi/common'
import { formPattern } from '@/unit/matchFormRegex'
export default {
  components: {
    FileUpload,
    DetailsPage
  },
  data() {
    return {
      ruleForm: {
        cmpName: '',
        cmpUnicode: '',
        cmpAddress: '',
        businessLicenseNamePath: '',
        leadIdcardFrontNamePath: '',
        leadIdcardBackNamePath: '',
        cmpCreditReportNamePath: '',
        financialReportsNamePath: '',
        balanceSheetNamePath: '',
        rulesNamePath: '',
        taxReturnNamePath: '',
        flowMeterNamePath: '',
        topFiveTransacNamePath: '',
        fundsCmpCode: ''
      },
      rules: {
        fundsCmpCode: [
          { required: true, message: '请选择代采机构', trigger: 'change' }
        ],
        contacts: [
          { min: 1, max: 50, message: '不超过50个字符', trigger: 'blur' }
        ],
        contactsPhone: [
          { ...formPattern.tel }
        ],
        cmpCreditReportNamePath: [
          { required: true, message: '请上传企业征信报告', trigger: 'blur' }
        ],
        financialReportsNamePath: [
          { required: true, message: '请上传财务报表', trigger: 'blur' }
        ],
        balanceSheetNamePath: [
          { required: true, message: '请上传资产负债表', trigger: 'blur' }
        ],
        rulesNamePath: [
          { required: true, message: '请上传章程', trigger: 'blur' }
        ],
        taxReturnNamePath: [
          { required: true, message: '请上传纳税申报表', trigger: 'blur' }
        ],
        flowMeterNamePath: [
          { required: true, message: '请上传流水表', trigger: 'blur' }
        ],
        topFiveTransacNamePath: [
          { required: true, message: '请上传上下游前五交易报表', trigger: 'blur' }
        ]
      },
      allCityOption: [],
      // 代采机构列表
      employerCmpList: [],
      detailsItemArr: [
        { label: '公司名称', value: 'cmpName' },
        { label: '统一社会信用代码', value: 'cmpUnicode' },
        { label: '所在地址', value: 'currAddr' },
        { label: '营业执照', imageSrc: 'businessLicenseNamePath' },
        { label: '法人身份证正面', imageSrc: 'leadIdcardFrontNamePath' },
        { label: '法人身份证反面', imageSrc: 'leadIdcardBackNamePath' }
      ]
    }
  },
  mounted() {
    // 获取所有的城市
    getSelectAreaTree('00', res => {
      this.allCityOption = [...res.data]
    })
    this.getCmpBaseinfoList('')
    this.userInfo = JSON.parse(localStorage.getItem('userInfoCust'))
    if (this.userInfo && this.userInfo.cmpId) {
      cmpBaseinfoDetail(this.userInfo.cmpId, res => {
        this.ruleForm.cmpCode = res.data.cmpCode
        this.ruleForm.cmpName = res.data.cmpName
        this.ruleForm.cmpUnicode = res.data.cmpUnicode
        this.ruleForm.currAddr = res.data.cmpAddress
        this.ruleForm.businessLicenseNamePath = res.data.businessLicense
        this.ruleForm.leadIdcardFrontNamePath = res.data.leadIdcardFront
        this.ruleForm.leadIdcardBackNamePath = res.data.leadIdcardBack
      })
    }
  },
  methods: {
    // 表单部分字段校验
    validateField(field) {
      this.$refs.ruleForm.validateField(field)
    },
    // 表单提交
    submitForm() {
      this.$refs['ruleForm'].validate((valid) => {
        if (valid) {
          agentFinancialInfoAdd({ ...this.ruleForm }, res => {
            this.$message.success('成功！')
            this.$router.push({
              path: '/quotaManage/index'
            })
          })
        }
      })
    },
    cancel() {
      this.$router.back(-1)
    },
    // 企业征信报告
    getcmpCreditReportFile(file) {
      this.ruleForm.cmpCreditReportNamePath = file.fileUrl
      this.ruleForm.cmpCreditReportName = file.fileName
      this.validateField('cmpCreditReportNamePath')
    },
    // 财务报表
    getFinancialReportsFile(file) {
      this.ruleForm.financialReportsNamePath = file.fileUrl
      this.ruleForm.financialReportsName = file.fileName
      this.validateField('financialReportsNamePath')
    },
    // 资产负债表
    getBalanceSheetFile(file) {
      this.ruleForm.balanceSheetNamePath = file.fileUrl
      this.ruleForm.balanceSheetName = file.fileName
      this.validateField('balanceSheetNamePath')
    },
    // 章程
    getRulesNameFile(file) {
      this.ruleForm.rulesNamePath = file.fileUrl
      this.ruleForm.rulesName = file.fileName
      this.validateField('rulesNamePath')
    },
    // 纳税申报表
    getTaxReturnFile(file) {
      this.ruleForm.taxReturnNamePath = file.fileUrl
      this.ruleForm.taxReturnName = file.fileName
      this.validateField('taxReturnNamePath')
    },
    // 流水表
    getFlowMeterNameFile(file) {
      this.ruleForm.flowMeterNamePath = file.fileUrl
      this.ruleForm.flowMeterName = file.fileName
      this.validateField('flowMeterNamePath')
    },
    // 上下游前五交易报表
    getTopFiveTransacFile(file) {
      this.ruleForm.topFiveTransacNamePath = file.fileUrl
      this.ruleForm.topFiveTransacName = file.fileName
      this.validateField('topFiveTransacNamePath')
    },
    // 其他附件
    getOthAccessFile(file) {
      this.ruleForm.othAccessNamePath = file.fileUrl
      this.ruleForm.othAccessName = file.fileName
    },
    getEmployerCmpName(obj) {
      this.ruleForm.fundsCmpCode = obj.cmpCode
      this.ruleForm.fundsCmpName = obj.cmpName
    },
    // 代采机构下拉数据
    getCmpBaseinfoList(val) {
      getCmpBaseinfoList(val, res => {
        const arr = res.data.filter(item => item.cmpRoleList.includes('04') && this.userInfo.cmpId !== item.cmpCode)
        this.employerCmpList = arr
      })
    }
  }
}
</script>

<style lang="scss" scoped>
// .page-form {
//   padding: 32px;
//   display: flex;
//   flex-wrap: wrap;
//   justify-content: space-between;
//   .el-form-item {
//     width: 45%;
//   }
// }
// .page-form:after{
//   content: "";
//   width: 45%;
// }
/deep/ .el-form-item__label {
  padding-left: 0;
}
.page-form .spread-over {
  width: 100%;
}
.dividing-line {
  width: 100%;
  height: 1px;
  opacity: 0.1;
}
</style>
