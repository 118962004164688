import { getRequest, postRequest } from './index'
// 获取省市区
export const getSelectAreaTree = (areaType, successCallback, failureCallback) => {
  getRequest('/dataDict/selectAreaTree?areaType=' + areaType, {}, res => (
    successCallback(res)
  ), error => {
    if (failureCallback)failureCallback(error)
  })
}
// 获取系统品种列表  不带分页
export const getGoodsList = (successCallback, failureCallback) => {
  getRequest('/goods/goodsList', {}, res => (
    successCallback(res)
  ), error => {
    if (failureCallback)failureCallback(error)
  })
}
// 库存分页
export const inventoryPage = (data, successCallback, failureCallback) => {
  postRequest('/wms/inventory/page', data, res => (
    successCallback(res)
  ), error => {
    if (failureCallback)failureCallback(error)
  })
}
// 仓库管理下拉
export const warehouseList = (warehouseName, successCallback, failureCallback) => {
  getRequest('/wms/warehouse/list?warehouseName=' + warehouseName, {}, res => (
    successCallback(res)
  ), error => {
    if (failureCallback)failureCallback(error)
  })
}
// 根据品名id获取物品件重列表
export const goodsWeightsListByGoodsId = (data, successCallback, failureCallback) => {
  postRequest(`/goods/goodsWeightsListByGoodsId`, data, res => (
    successCallback(res)
  ), error => {
    if (failureCallback)failureCallback(error)
  })
}
// 企业基础信息下拉带模糊搜索
export const getCmpBaseinfoList = (cmpName, successCallback, failureCallback) => {
  getRequest('/cmp/baseinfo/list?cmpName=' + cmpName, {}, res => (
    successCallback(res)
  ), error => {
    if (failureCallback)failureCallback(error)
  })
}
// 运算函数管理
// 运算类型：1-加、2-减、3-乘、4-除(四舍五入)
export const operationMethods = (data, successCallback, failureCallback) => {
  getRequest(`/math/operation?type=${data.type}&param1=${data.param1}&param2=${data.param2}`, {}, res => (
    successCallback(res)
  ), error => {
    if (failureCallback)failureCallback(error)
  })
}
// 获取字典数据
export const getSelectAllDict = new Promise((resolve, reject) => {
  getRequest('/dataDict/selectAllDict', {}, res => (
    resolve(res)
  ), error => {
    reject(error)
  })
})
// 获取首页的统计
export const agentPurchaseContractHomeSummary = (successCallback, failureCallback) => {
  postRequest('/agentPurchaseContract/homeSummary', {}, res => (
    successCallback(res)
  ), error => {
    if (failureCallback)failureCallback(error)
  })
}
// 材质的列表
export const getGoodsMaterialsList = (data, successCallback, failureCallback) => {
  postRequest(
    '/goods/goodsMaterialsList',
    data,
    res => successCallback(res),
    error => {
      if (failureCallback) failureCallback(error)
    }
  )
}
// 品种规格列表
export const getGoodsSpecsList = (data, successCallback, failureCallback) => {
  postRequest(
    '/goods/goodsSpecsList',
    data,
    res => successCallback(res),
    error => {
      if (failureCallback) failureCallback(error)
    }
  )
}
// 产地分页列表
export const getGoodsProductionPage = (
  data,
  successCallback,
  failureCallback
) => {
  postRequest(
    '/goods/goodsProductionPage',
    data,
    res => successCallback(res),
    error => {
      if (failureCallback) failureCallback(error)
    }
  )
}
// 物品件重的列表
export const getGoodsWeightsPage = (data, successCallback, failureCallback) => {
  postRequest(
    '/goods/goodsWeightsPage',
    data,
    res => successCallback(res),
    error => {
      if (failureCallback) failureCallback(error)
    }
  )
}
// 物品件重的列表2
export const getGoodsWeightsByGoodsId = (data, successCallback, failureCallback) => {
  postRequest(
    '/goods/goodsWeightsByGoodsId',
    data,
    res => successCallback(res),
    error => {
      if (failureCallback) failureCallback(error)
    }
  )
}
